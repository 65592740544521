<template>
<div>
  <input class="form-control" type="text" v-maska="'##:##'" maxlength="5" :class="classHighlight" :disabled="disabled" :placeholder="placeholder" v-model="state.value" @blur="handleBlur" @keydown="onKeydownInput" @change="changeTime">
</div>
</template>

<script>
import {reactive, watch} from "vue";
import {cloneDeep} from "lodash";

export default {
  name: "TimeCustom",
  props: {
    propValue: {
      type: String
    },
    placeholder: {
      style: String,
      default: 'hh:mm'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classHighlight: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const state = reactive({
      value: props.propValue,
      valueTemp: '',
      house: "",
      minute: ""
    })

    watch(() => props.propValue, (value) => {
      state.value = value
    })

    const onKeydownInput = (e) => {
      const regex = /[a-zA-Z]+/;
      const regex_special = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

      if ((regex.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') || regex_special.test(e.key) || e.key === '`' || e.key === ' '|| e.key === '~') {
        e.preventDefault();
      }
    }


    const convertMinute = (minute, hour) => {
      let temp = parseInt(minute);
      if (temp === 0) return '00';
      if (parseInt(hour) > 47) {
        return '59';
      }
      return temp > 60 ? '60' : `${minute}`;
    }

    const convertHour = (hour) => {
      return parseInt(hour) > 47 ? '47' : hour.toString()
    }

    const convertTime = (time) => {
      const pattern = /:/;

      switch (time.length) {
        case 0: {
          state.value = `00:00`;
          break
        }
        case 1: {
          state.value = `0${time}:00`;
          break
        }
        case 2: {
          state.value = `${convertHour(time)}:00`;
          break
        }
        case 3: {
          if (pattern.test(time)) {
            state.value = `${convertHour(time.slice(0,2))}:00`;
          } else {
            state.value = `${convertHour(time.slice(0,2))}:0${convertMinute(time.slice(-1), time.slice(0,2))}`;
          }
          break
        }
        case 4: {
          if (pattern.test(time)) {
            state.value = `${convertHour(time.slice(0,2))}:${convertMinute(time.slice(-1), time.slice(0,2))}`;
          } else {
            state.value = `${convertHour(time.slice(0,2))}:${convertMinute(time.slice(-2), time.slice(0,2))}`;
          }
          break
        }
        case 5: {
          state.value = `${convertHour(time.slice(0,2))}:${convertMinute(time.slice(-2), time.slice(0,2))}`;
          break
        }
      }
    }

    const handleBlur = (e) => {
      convertTime(e.target.value);
      emit('updateInput', state.value)
    }

    const changeTime = (e) => {
      const time = cloneDeep(e.target.value);
      convertTime(time);
      emit('updateInput', state.value)
    }

    return {
      state,
      changeTime,
      onKeydownInput,
      handleBlur
    }
  }
}
</script>

<style scoped>
.highlight {
  border-color: #edc86f;
}
</style>
